export const RouterName = {
  auth: {
    login: "/auth/login",
    signup: "",
    forgotPassword: "/auth/forgot-password",
    maintenance: "/auth/maintenance",
  },
  home: {
    client_type: {
      client_type: "/client-type",
      client_type_form: "/client-type/score",
    },
    question_category: {
      question_category: "/question-category",
      question_list: "/question-list",
    },
    client: {
      client: "/client",
      client_document: "/client/document/:id",
      client_properties: "/client/properties/:id",
    },
    insurance: {
      insurance: "/insurance",
      client_type: "/insurance/client-type",
    },
    owner: {
      owner: "/owner",
    },
  },
  request: {
    DEPOSIT: {
      all: "/request/deposit/all",
      pending: "/request/deposit/pending",
      awaitingclientresponse: "/request/deposit/awaitingclientresponse",
      completed: "/request/deposit/completed",
      awaitForPackages: "/request/deposit/awaitForPackages",
      viewForPackages: "/request/deposit/viewForPackages",
      rejected: "/request/deposit/rejected",
      expired: "/request/deposit/expired",
    },
    RENT: {
      all: "/request/rent/all",
      pending: "/request/rent/pending",
      awaitingclientresponse: "/request/rent/awaitingclientresponse",
      completed: "/request/rent/completed",
      awaitForPackages: "/request/rent/awaitForPackages",
      viewForPackages: "/request/rent/viewForPackages",
      missingdocuments: "/request/rent/missingdocuments",
      rejected: "/request/rent/rejected",
      expired: "/request/rent/expired",
    },
    WITH_OUT_PROPRITY: {
      all: "/request/without-propriety",
    },
  },
  request_document: "/request/document/:request_id",
  request_package: "/request/packages/:request_id",
  request_package_rabun: "/request/details/:type_request/:request_id",
  request_details: "/request/details/:request_id",
  request_client_questionnaire: "/request/details/score/:request_id",
  admins: {
    admins: "/admins",
    privileges: "/privileges",
  },
  landing_page: {
    slider_show: "/slider_show",
    Trusted_by: "/trusted_by",
    Safe_smart_solution: "/safe_smart_solution",
    app_benefits: "/app_benefits",
    review: "/review",
    location: "/location",
    faq: "/faq",
    stats: "/stats",
    policy_prices: "/policy_prices",
    how_it_work: "/how_it_work",
    Proprietor_features: "/proprietor_features",
    Partner_benefits: "/partner_benefits",
  },
  settings: {
    settings: "/settings",
    legal_information: "/legal_information",
  },
};
