const UrlApi = {
  // for local
  baseUrl: `${process.env.REACT_APP_BASEURL}/admin/`,
  baseUrlImage: `${process.env.REACT_APP_BASEURL}/uploads/`,

  // auth
  auth: {
    login: "auth/login",
    autologin: "auth/autologin",
    signup: "auth/signup",
    logout: "auth/logout",
  },
  admins: {
    get: "admins/get",
    getInsurance: "admins/get-insurance",
    add: "admins/add",
    addInsurance: "admins/add-insurance",
    edit: "admins/edit",
    editInsurance: "admins/edit-insurance",
    delete: "admins/delete",
    getUserType: "admins/get_user_type",
    getUserPrivilegs: "admins/privileg",
    addUserPrivilegs: "admins/addprivileg",
    editUserPrivilegs: "admins/editprivileg",
    deleteprivileg: "admins/deleteprivileg",
  },
  clientType: {
    get: "client-type/get",
    add: "client-type/add",
    edit: "client-type/edit",
    delete: "client-type/delete",
    getById: "client-type/getById",
    editById: "client-type/editById",
  },
  client: {
    get: "client/get",
    add: "client/add",
    edit: "client/edit",
    delete: "client/delete",
    getcountries: "client/getcountries",
    get_doucment: "client/get-doucment",
    get_gallary: "client/get_gallary",
    get_property: "client/get-my-properties",
    get_all_doucment: "client/get-all-doucment",
    update_doucment: "client/update-doucment",
  },
  questions_list: {
    get: "questions-list/get",
    add: "questions-list/add",
    edit: "questions-list/edit",
    delete: "questions-list/delete",
  },
  questions_categories: {
    get: "questions-categories/get",
    add: "questions-categories/add",
    edit: "questions-categories/edit",
    delete: "questions-categories/delete",
  },
  landing_page: {
    slider_show: {
      get: "landingpage/slider_show/get",
      add: "landingpage/slider_show/add",
      edit: "landingpage/slider_show/edit",
      delete: "landingpage/slider_show/delete",
    },
    trusted_by: {
      get: "landingpage/trusted_by/get",
      add: "landingpage/trusted_by/add",
      edit: "landingpage/trusted_by/edit",
      delete: "landingpage/trusted_by/delete",
    },
    safe_smart_solution: {
      get: "landingpage/safe_smart_solution/get",
      add: "landingpage/safe_smart_solution/add",
      edit: "landingpage/safe_smart_solution/edit",
      delete: "landingpage/safe_smart_solution/delete",
    },
    app_benefits: {
      get: "landingpage/app_benefits/get",
      add: "landingpage/app_benefits/add",
      edit: "landingpage/app_benefits/edit",
      delete: "landingpage/app_benefits/delete",
    },
    review: {
      get: "landingpage/review/get",
      add: "landingpage/review/add",
      edit: "landingpage/review/edit",
      delete: "landingpage/review/delete",
    },
    location: {
      get: "landingpage/location/get",
      add: "landingpage/location/add",
      edit: "landingpage/location/edit",
      delete: "landingpage/location/delete",
    },
    faq: {
      get: "landingpage/faq/get",
      add: "landingpage/faq/add",
      edit: "landingpage/faq/edit",
      delete: "landingpage/faq/delete",
    },
    stats: {
      get: "landingpage/stats/get",
      add: "landingpage/stats/add",
      edit: "landingpage/stats/edit",
      delete: "landingpage/stats/delete",
    },
    policy_price: {
      get: "landingpage/policy_price/get",
      add: "landingpage/policy_price/add",
      edit: "landingpage/policy_price/edit",
      delete: "landingpage/policy_price/delete",
    },
    how_it_work: {
      get: "landingpage/how_it_work/get",
      add: "landingpage/how_it_work/add",
      edit: "landingpage/how_it_work/edit",
      delete: "landingpage/how_it_work/delete",
    },
    partner_benefits: {
      get: "landingpage/partner_benefits/get",
      add: "landingpage/partner_benefits/add",
      edit: "landingpage/partner_benefits/edit",
      delete: "landingpage/partner_benefits/delete",
    },
    proprietor_features: {
      get: "landingpage/proprietor_features/get",
      add: "landingpage/proprietor_features/add",
      edit: "landingpage/proprietor_features/edit",
      delete: "landingpage/proprietor_features/delete",
    },
  },
  settings: {
    get: "settings/get",
    edit: "settings/edit",
  },
  request: {
    get: "request/get",
    get_count: "request/get_count",
    getInsuranceRequest: "request/get_insurance_request",
    edit: "request/edit",
    delete: "request/delete",
    get_insurance_score: "request/get_insurance_score",
    get_client_questionnaire: "request/get-by-request",
    get_status_by_request: "request/get-status-by-request",
  },
  Packages: {
    get: "packages/get",
    edit: "packages/edit",
    add: "packages/add",
    delete: "packages/delete",
  },
};

export default UrlApi;
