import {
  ChackImageIsFile,
  CheckDataIfExit,
  getLinkPagination,
  user_type,
} from "src/helper/publicFunction";
import { setLoading } from "src/store/customizer/CustomizerSlice";
import { GetFunction, PostFunction } from "src/utils/axios_connection";
import UrlApi from "src/utils/Url";

export const GetProperties =
  ({ callback, id }) =>
  async (dispatch, getState) => {
    try {
      let link = `${UrlApi.client.get_property}?client_id=${id}`;
      const response = await GetFunction({
        data: {
          client_id: id,
        },
        Route: link,
        showalert: false,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };

export const GetGallery =
  ({ callback, id }) =>
  async (dispatch, getState) => {
    try {
      let link = `${UrlApi.client.get_gallary}?property_id=${id}`;
      const response = await GetFunction({
        data: {
          property_id: id,
        },
        Route: link,
        showalert: false,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };

export const GetOwner =
  ({ callback, page, orderBy, rowsPerPage, search, typeOrder }) =>
  async (dispatch, getState) => {
    try {
      let fieldsSearch = ["first_name", "last_name", "email", "nationality"];

      let link = `${UrlApi.client.get}?${getLinkPagination({
        page,
        fieldsSearch,
        orderBy: orderBy ?? "created_date",
        rowsPerPage,
        search,
        typeOrder,
      })}`;
      const response = await PostFunction({
        data: {
          type: user_type.owner,
        },
        Route: link,
        showalert: false,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };

export const getCountries =
  ({ callback }) =>
  async (dispatch, getState) => {
    try {
      let link = UrlApi.client.getcountries;
      const response = await PostFunction({
        data: {},
        Route: link,
        showalert: false,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      callback(response);
    } catch (err) {
      callback(false);
    }
  };

export const ManageOwner =
  ({ callback, values, editMode }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      var bodyFormData = {};
      if (editMode) {
        bodyFormData.id = values?.id;
      } else {
        bodyFormData.type = user_type.owner;
      }
      if (CheckDataIfExit(values?.first_name)) {
        bodyFormData.first_name = values?.first_name?.trim();
      }
      if (CheckDataIfExit(values?.last_name)) {
        bodyFormData.last_name = values?.last_name?.trim();
      }
      if (CheckDataIfExit(values?.date_of_birth)) {
        bodyFormData.date_of_birth = values?.date_of_birth;
      }
      if (CheckDataIfExit(values?.nationality)) {
        bodyFormData.nationality = values?.nationality;
      }
      if (CheckDataIfExit(values?.address_details)) {
        bodyFormData.address_details = values?.address_details?.trim();
      }
      if (CheckDataIfExit(values?.client_type_id)) {
        bodyFormData.client_type_id = values?.client_type_id;
      }
      if (CheckDataIfExit(values?.phone_code)) {
        bodyFormData.phone_code = values?.phone_code?.trim();
      }
      if (CheckDataIfExit(values?.phone)) {
        bodyFormData.phone = values?.phone;
      }
      if (CheckDataIfExit(values?.email)) {
        bodyFormData.email = values?.email?.trim();
      }
      if (CheckDataIfExit(values?.password)) {
        bodyFormData.password = values?.password?.trim();
      }
      bodyFormData.type = "owner";
      const response = await PostFunction({
        data: bodyFormData,
        Route: editMode ? UrlApi.client.edit : UrlApi.client.add,
        showalert: true,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      dispatch(setLoading(false));
      callback(response);
    } catch (err) {
      dispatch(setLoading(false));
      callback(false);
    }
  };

export const EditOwner =
  ({ callback, allData, id, nameFailed, dataFailed }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      let data;
      if (allData) {
        data = allData;
      } else {
        data = {
          id: id,
          [nameFailed]: dataFailed,
        };
      }
      const response = await PostFunction({
        data,
        Route: UrlApi.client.edit,
        showalert: true,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      dispatch(setLoading(false));
      callback(response);
    } catch (err) {
      dispatch(setLoading(false));
      callback(false);
    }
  };

export const DeleteOwner =
  ({ callback, id }) =>
  async (dispatch, getState) => {
    try {
      dispatch(setLoading(true));
      let data = {
        ids: JSON.stringify(id),
      };
      const response = await PostFunction({
        data,
        Route: UrlApi.client.delete,
        showalert: true,
        state: getState,
        isMultipart: false,
        dispatch: dispatch,
      });
      dispatch(setLoading(false));
      callback(response);
    } catch (err) {
      dispatch(setLoading(false));
      callback(false);
    }
  };
