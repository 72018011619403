import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import Loadable from "../layouts/full/shared/loadable/Loadable";
import { RouterName } from "./RouterName";
import { dataLocalStorage, getLocalStorage } from "src/helper/publicFunction";
import Properties from "src/pages/owner/client_properties";

/* ***Layouts**** */
const FullLayout = Loadable(lazy(() => import("../layouts/full/FullLayout")));
const BlankLayout = Loadable(
  lazy(() => import("../layouts/blank/BlankLayout"))
);

/* ****Pages***** */
const ScorePage = Loadable(
  lazy(() => import("src/pages/request/details/score_page"))
);
const Settings = Loadable(lazy(() => import("src/pages/settings/view")));
const LegalInformation = Loadable(
  lazy(() => import("src/pages/settings/legal_information"))
);

const Request = Loadable(lazy(() => import("src/pages/request/view")));
const RequestWithOutProprity = Loadable(
  lazy(() => import("src/pages/request/request_with_out_proprity/view"))
);

const Packages = Loadable(
  lazy(() => import("src/pages/request/packages/view"))
);
const RabunPackages = Loadable(
  lazy(() => import("src/pages/request/rabun_packages/view"))
);
const Details = Loadable(lazy(() => import("src/pages/request/details/view")));

// start landingpage
const SliderShow = Loadable(
  lazy(() => import("src/pages/landing_page/slidershow/view"))
);
const Stats = Loadable(lazy(() => import("src/pages/landing_page/stats/view")));
const PolicyPrices = Loadable(
  lazy(() => import("src/pages/landing_page/policy_price/view"))
);
const HowItWork = Loadable(
  lazy(() => import("src/pages/landing_page/how_it_work/view"))
);
const Proprietor_features = Loadable(
  lazy(() => import("src/pages/landing_page/proprietor_features/view"))
);
const Partner_benefits = Loadable(
  lazy(() => import("src/pages/landing_page/partner_benefits/view"))
);

const Trusted_by = Loadable(
  lazy(() => import("src/pages/landing_page/trusted_by/view"))
);
const SafeSmartSolution = Loadable(
  lazy(() => import("src/pages/landing_page/safe_smart_solution/view"))
);
const AppBenefits = Loadable(
  lazy(() => import("src/pages/landing_page/app_benefits/view"))
);
const Review = Loadable(
  lazy(() => import("src/pages/landing_page/review/view"))
);
const Location = Loadable(
  lazy(() => import("src/pages/landing_page/location/view"))
);
const FAQ = Loadable(lazy(() => import("src/pages/landing_page/faq/view")));
// end landingpage

const ClientType = Loadable(lazy(() => import("src/pages/client_type/view")));

const Client = Loadable(lazy(() => import("src/pages/client/view")));
const Owner = Loadable(lazy(() => import("src/pages/owner/view")));

const ClientDocument = Loadable(
  lazy(() => import("src/pages/client/documents"))
);

const Insurance = Loadable(lazy(() => import("src/pages/insurance/view")));
const FormClientType = Loadable(
  lazy(() => import("src/pages/client_type/form/view"))
);

const QuestionCategory = Loadable(
  lazy(() => import("src/pages/question_category/view"))
);
const QuestionList = Loadable(
  lazy(() => import("src/pages/question_category/question_list/view"))
);
const AdminsPage = Loadable(lazy(() => import("../pages/admins/admins/view")));
const PrivilegesPage = Loadable(
  lazy(() => import("../pages/admins/privileges/view"))
);

// authentication
const Login = Loadable(
  lazy(() => import("../views/authentication/auth/Login"))
);

const ForgotPassword = Loadable(
  lazy(() => import("../views/authentication/auth/ForgotPassword"))
);
const Error = Loadable(lazy(() => import("../views/authentication/Error")));
const Maintenance = Loadable(
  lazy(() => import("../views/authentication/Maintenance"))
);

let data = getLocalStorage(dataLocalStorage.privileges);

const Router = [
  {
    path: "/",
    element: <FullLayout />,
    children: [
      {
        path: "/",
        exact: true,
        element: <Navigate to={RouterName.home.client_type.client_type} />,
      },
      data["2"]?.access_read === 1 && {
        path: RouterName.home.client_type.client_type,
        exact: true,
        element: <ClientType />,
      },
      data["2"]?.access_read === 1 && {
        path: RouterName.home.insurance.client_type,
        exact: true,
        element: <ClientType />,
      },
      data["4"]?.access_read === 1 && {
        path: RouterName.home.question_category.question_category,
        exact: true,
        element: <QuestionCategory />,
      },
      data["3"]?.access_read === 1 && {
        path: RouterName.home.question_category.question_list,
        exact: true,
        element: <QuestionList />,
      },
      data["5"]?.access_read === 1 && {
        path: RouterName.home.client_type.client_type_form,
        exact: true,
        element: <FormClientType />,
      },
      data["9"]?.access_read === 1 && {
        path: RouterName.home.insurance.insurance,
        exact: true,
        element: <Insurance />,
      },

      data["1"]?.access_read === 1 && {
        path: RouterName.admins.admins,
        exact: true,
        element: <AdminsPage />,
      },
      data["1"]?.access_read === 1 && {
        path: RouterName.admins.privileges,
        exact: true,
        element: <PrivilegesPage />,
      },
      data["7"]?.access_read === 1 && {
        path: RouterName.home.client.client,
        exact: true,
        element: <Client />,
      },
      data["13"]?.access_read === 1 && {
        path: RouterName.home.owner.owner,
        exact: true,
        element: <Owner />,
      },
      data["7"]?.access_read === 1 && {
        path: RouterName.home.client.client_document,
        exact: true,
        element: <ClientDocument />,
      },
      data["7"]?.access_read === 1 && {
        path: RouterName.home.client.client_properties,
        exact: true,
        element: <Properties />,
      },

      // start landingpage
      data["10"]?.access_read === 1 && {
        path: RouterName.landing_page.slider_show,
        exact: true,
        element: <SliderShow />,
      },
      data["10"]?.access_read === 1 && {
        path: RouterName.landing_page.stats,
        exact: true,
        element: <Stats />,
      },
      data["10"]?.access_read === 1 && {
        path: RouterName.landing_page.policy_prices,
        exact: true,
        element: <PolicyPrices />,
      },
      data["10"]?.access_read === 1 && {
        path: RouterName.landing_page.how_it_work,
        exact: true,
        element: <HowItWork />,
      },
      data["10"]?.access_read === 1 && {
        path: RouterName.landing_page.Proprietor_features,
        exact: true,
        element: <Proprietor_features />,
      },
      data["10"]?.access_read === 1 && {
        path: RouterName.landing_page.Partner_benefits,
        exact: true,
        element: <Partner_benefits />,
      },

      data["10"]?.access_read === 1 && {
        path: RouterName.landing_page.Trusted_by,
        exact: true,
        element: <Trusted_by />,
      },
      data["10"]?.access_read === 1 && {
        path: RouterName.landing_page.Safe_smart_solution,
        exact: true,
        element: <SafeSmartSolution />,
      },
      data["10"]?.access_read === 1 && {
        path: RouterName.landing_page.app_benefits,
        exact: true,
        element: <AppBenefits />,
      },
      data["10"]?.access_read === 1 && {
        path: RouterName.landing_page.review,
        exact: true,
        element: <Review />,
      },
      data["10"]?.access_read === 1 && {
        path: RouterName.landing_page.location,
        exact: true,
        element: <Location />,
      },
      data["10"]?.access_read === 1 && {
        path: RouterName.landing_page.faq,
        exact: true,
        element: <FAQ />,
      },
      // end landingpage
      data["11"]?.access_read === 1 && {
        path: RouterName.settings.settings,
        exact: true,
        element: <Settings />,
      },
      data["11"]?.access_read === 1 && {
        path: RouterName.settings.legal_information,
        exact: true,
        element: <LegalInformation />,
      },
      data["12"]?.access_read === 1 && {
        path: "/request/:type_request/:status_request",
        exact: true,
        element: <Request />,
      },
      data["12"]?.access_read === 1 && {
        path: RouterName.request.WITH_OUT_PROPRITY.all,
        exact: true,
        element: <RequestWithOutProprity />,
      },
      data["12"]?.access_read === 1 && {
        path: RouterName.request_document,
        exact: true,
        element: <ClientDocument />,
      },
      data["12"]?.access_read === 1 && {
        path: RouterName.request_package_rabun,
        exact: true,
        element: <RabunPackages />,
      },
      {
        path: RouterName.request_package,
        exact: true,
        element: <Packages />,
      },
      {
        path: RouterName.request_details,
        exact: true,
        element: <Details />,
      },
      {
        path: RouterName.request_client_questionnaire,
        exact: true,
        element: <ScorePage />,
      },
      { path: "/404", element: <Error /> },
      { path: "*", element: <Navigate to="/" /> },
    ],
  },
  {
    path: "/",
    element: <BlankLayout />,
    children: [
      { path: "/auth/404", element: <Error /> },
      { path: RouterName.auth.login, element: <Login /> },
      { path: RouterName.auth.forgotPassword, element: <ForgotPassword /> },
      { path: RouterName.auth.maintenance, element: <Maintenance /> },
      { path: "*", element: <Navigate to="/auth/404" /> },
    ],
  },
];

export default Router;
