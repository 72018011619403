import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { isValidArray } from "src/helper/publicFunction";
import { GetGallery, GetProperties } from "./actions";
import { useLocation, useParams } from "react-router";
import "./style.css";
import UrlApi from "src/utils/Url";

const PropertyComponent = ({ property }) => {
  const [showGallery, setShowGallery] = useState(false);
  const [galleryImages, setGalleryImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchGalleryImages = () => {
    setLoading(true);
    dispatch(
      GetGallery({
        callback: (data) => {
          if (data && isValidArray(data.data)) {
            setGalleryImages(data.data);
          } else {
            setGalleryImages([]);
          }
          setLoading(false);
        },
        id: property.id,
      })
    );
  };

  const toggleGallery = () => {
    if (!showGallery) {
      fetchGalleryImages();
    }
    setShowGallery(!showGallery);
  };

  return (
    <div className="property-card">
      <div
        className="property-image"
        style={{
          backgroundImage: `url(${UrlApi.baseUrlImage + property.main_image})`,
        }}
      />
      <div className="property-details">
        <p className="detail-item">
          <strong>Area:</strong> {property.area}
        </p>
        <p className="detail-item">
          <strong>Street:</strong> {property.street}
        </p>
        <p className="detail-item">
          <strong>Building:</strong> {property.building}
        </p>
        <p className="detail-item">
          <strong>Floor:</strong> {property.floor}
        </p>
        <p className="detail-item">
          <strong>Pet Allowed:</strong> {property.is_pet_allowed ? "Yes" : "No"}
        </p>
        <p className="detail-item">
          <strong>Furnished:</strong> {property.is_furnished ? "Yes" : "No"}
        </p>
        <p className="detail-item">
          <strong>Duration:</strong> {property.duration}
        </p>
        <p className="detail-item">
          <strong>Installments:</strong> {property.installments}
        </p>
        <p className="detail-item">
          <strong>City:</strong> {property.city_name}
        </p>
        <p className="detail-item">
          <strong>Country:</strong> {property.country_name}
        </p>
      </div>
      <button className="button" onClick={toggleGallery}>
        Show Gallery
      </button>

      <div className={`modal ${showGallery ? "show" : ""}`}>
        <div className="modal-content">
          <span className="close-button" onClick={toggleGallery}>
            &times;
          </span>
          <h2>Property Gallery</h2>
          {loading ? (
            <p>Loading gallery images...</p>
          ) : (
            <div className="gallery-container">
              {isValidArray(galleryImages) ? (
                galleryImages.map((image, index) => (
                  <img
                    key={index}
                    src={UrlApi.baseUrlImage + image.image}
                    alt={`Gallery image ${index + 1}`}
                    className="gallery-image"
                  />
                ))
              ) : (
                <p>No gallery images available.</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Properties = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [search, setSearch] = useState("");
  const [loadingData, setLoadingData] = useState(true);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState(0);

  const getData = () => {
    setLoadingData(true);
    setData([]);
    dispatch(
      GetProperties({
        id,
        page,
        rowsPerPage,
        search,
        callback: (data) => {
          if (isValidArray(data?.data)) {
            setData(data.data);
          } else {
            setData([]);
            setTotalData(0);
          }
          setLoadingData(false);
        },
      })
    );
  };

  useEffect(() => {
    getData();
  }, [page, rowsPerPage, search]);

  return (
    <div className="properties-container">
      {loadingData ? (
        <p>Loading properties...</p>
      ) : (
        <>
          {data.map((property) => (
            <PropertyComponent key={property.id} property={property} />
          ))}
        </>
      )}
    </div>
  );
};

export default Properties;
